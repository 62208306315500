<template>
  <div class="mt-4 mb-4">
    <div class="add-user" v-if="isAdmin">
      <b-button
        class="add-user-btn"
        v-b-toggle.addUser
        variant="primary"
        @click="addUserVisible=(addUserVisible==='down')?'up':'down'"
      >
        Add User to {{projectName}}
        <i :class="'fa fa-caret-' + addUserVisible" />
      </b-button>
      <b-collapse id="addUser">
        <b-card class="add-user-card texture">
          <b-form aria-autocomplete="off" @submit.prevent="onAddUser">
            <b-form-group
              id="fieldset1"
              :description="newUserPriv==='admin'?'New user can add/remove documents, users & systems':'New user has read-only access'"
              label="User Email"
              label-for="new-user-input"
            >
              <b-form-input
                placeholder="user@example.com"
                id="new-user-input"
                v-model.trim="newUserEmail"
                type="email"
              ></b-form-input>
              <b-form-radio-group
                class="mt-2"
                id="adminSelect"
                v-model="newUserPriv"
                name="newUserPriv"
              >
                <b-form-radio value="admin">Admin</b-form-radio>
                <b-form-radio value="user">User</b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-button type="submit" size="sm" class variant="success">Submit</b-button>
          </b-form>
          <b-alert
            variant="danger"
            dismissible
            :show="errorAlert"
            @dismissed="errorAlert=false"
            class="mt-3"
          >User already in project</b-alert>
        </b-card>
      </b-collapse>
    </div>
    <div class="all-contacts mb-3">
      <h3 class="mb-3 text-center">
        <u>Key Contact</u>
      </h3>
      <div class="key-contact text-center texture font-weight-bold">
        <p v-if="project.key_contact.name" class="mb-0">{{project.key_contact.name}}</p>
        <p
          v-else
          class="mb-0 small)"
        >{{project.key_contact.name? project.key_contact.name: "User Unregistered" }}</p>
        <p class="m-0">
          <a :href="'mailto:'+project.key_contact.email">{{project.key_contact.email}}</a>
        </p>
        <a :href="'tel:'+project.key_contact.phone" class="phone">
          <i v-if="project.key_contact.phone" class="fa fa-phone-square" />
          {{project.key_contact.phone}}
        </a>
      </div>
      <h3 class="mt-5">
        <u>Project Members</u>
      </h3>
      <div class="contacts mt-3 mb-3 texture">
        <ContactItem
          :user="user"
          v-for="(user,index) in users"
          :key="index"
          :isAdmin="isAdmin"
          @removeUser="handleRemoveUser"
          :projectName="projectName"
          :myId="myId"
        />
      </div>
      <h3 class="mt-5">
        <u>STI Representatives</u>
      </h3>
      <div class="contacts mt-3 mb-3 texture">
        <ContactItem
          :user="rep"
          v-for="(rep,index) in repsFormatted"
          :key="index"
          :isAdmin="false"
          :myId="myId"
        />
      </div>
    </div>
    <div v-if="project.details.where_to_buy_visible == 1">
      <h3 class="mt-5 text-center">
        <u>Where to Buy</u>
      </h3>
      <div class="radius mb-2">
        <h5>Search Radius</h5>
        <b-form-select class="mt-3" v-model="radiusSelected" :options="radiusOptions"></b-form-select>
      </div>
      <GoogleMaps :radius="radiusSelected" :proj-details="project.details" />
    </div>
  </div>
</template>

<script>
import ContactItem from "@/components/ContactItem";
import { post, del } from "@/utils/api";
import GoogleMaps from "@/components/GoogleMaps";
export default {
  props: ["project", "isAdmin", "projectName", "myId", "reps"],
  components: {
    ContactItem,
    GoogleMaps
  },
  data() {
    return {
      users: this.project.users.filter(
        user => user.email !== this.project.key_contact.email
      ),
      newUserEmail: "",
      newUserPriv: "admin",
      errorAlert: false,
      addUserVisible: "down",
      radiusSelected: 25,
      radiusOptions: [
        { value: 10, text: "10 mi" },
        { value: 25, text: "25 mi" },
        { value: 50, text: "50 mi" },
        { value: 100, text: "100 mi" },
        { value: 500, text: "500 mi" }
      ]
    };
  },
  methods: {
    onAddUser() {
      if (!this.newUserEmail) return;
      post("barrier-management/add-user", {
        project_id: this.project.details.id,
        role: this.newUserPriv,
        email: this.newUserEmail
      })
        .then(({ data }) => {
          this.users.splice(this.users.length, 1, data);
          this.$emit("reloadData");
        })
        .catch(() => {
          this.errorAlert = true;
        });
      this.newUserEmail = "";
    },
    handleRemoveUser(email) {
      this.users = this.users.filter(user => user.email !== email);
      del(
        `barrier-management/remove-user?email=${email}&project_id=${this.project.details.id}`
      )
        .then(() => {
          this.$emit("reloadData");
        })
        .catch(err => err);
    }
  },
  computed: {
    repsFormatted() {
      return this.reps.map(rep => {
        return {
          name: rep.first_name + " " + rep.last_name,
          email: rep.email,
          phone: rep.phone,
          title: rep.role
        };
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.contacts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid #ddd;
  box-shadow: 4px 4px 2px -2px rgba(0, 0, 0, 0.4);
}
.all-contacts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.add-user {
  display: flex;
  position: absolute;
}
.add-user-btn {
  max-width: 200px;
  box-shadow: 3px 3px 2px -2px rgba(0, 0, 0, 0.4);
}
.add-user-btn:active {
  outline: none !important;
}
.add-user-card {
  max-width: 500px;
  padding: 10px;
  box-shadow: 4px 4px 2px -2px rgba(0, 0, 0, 0.4);
}
.key-contact {
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 3px 3px 2px -2px rgba(0, 0, 0, 0.4);
}
a.phone {
  color: black;
  text-decoration: none;
}
.embed-responsive {
  height: 600px;
}
.retailers-loading {
  font-size: 50px;
  color: #0063a1;
  position: relative;
  top: 150px;
  text-align: center;
  display: block;
}
.radius {
  h5 {
    margin: 11px 10px 0 0;
  }
  select {
    width: 100px;
  }
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 700px) {
  .add-user {
    position: relative;
    flex-direction: column;
    margin: 0 0 15px 0;
  }
}
</style>

