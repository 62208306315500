<template>
  <Map :data="distributors" :key="reset" />
</template>

<script>
import qs from "qs";
import Map from "./Map";
import axios from "axios";
export default {
  props: ["radius", "proj-details"],
  components: {
    Map
  },
  computed: {
    address() {
      const {
        location_street: street,
        location_city: city,
        location_state: state,
        location_zip: zip,
        location_country: country
      } = this.projDetails;
      return `${street}, ${city}, ${state} ${zip} ${country}`;
    }
  },
  data() {
    return {
      distributors: [],
      reset: true
    };
  },
  watch: {
    radius() {
      this.getDistributors();
    }
  },
  mounted() {
    this.getDistributors();
  },
  methods: {
    getDistributors() {
      axios
        .get(process.env.VUE_APP_ROOT_API + "distributors", {
          params: {
            limit: -1,
            location: {
              search: this.address,
              radius: this.radius,
              radius_units: "miles"
            }
          },
          paramsSerializer: params => qs.stringify(params, { encode: false })
        })
        .then(({ data }) => {
          this.reset = !this.reset;
          this.distributors = [...data.data];
        });
    }
  }
};
</script>

<style>
</style>