<template>
  <div class="d-flex map-row">
    <div id="distributor-list">
      <h3 class="text-center">Distributors</h3>
      <div v-for="(dist,i) in data" :key="dist.id" class="distributor-item border-bottom px-2 py-4">
        <h4 @click="openInfowindow(i)">{{ dist.name }}</h4>
        <p v-if="dist.description">{{ dist.description }}</p>
        <p>
          {{ dist.address_1 }} {{ dist.address_2 }}
          <br />
          {{ dist.city || '' }}, {{ dist.state || '' }} {{ dist.zip || '' }}
        </p>
        <p>
          <b>Phone:</b>
          <a :href="`tel:${dist.phone}`">{{ dist.phone }}</a>
        </p>
        <a
          target="_blank"
          :href="`https://www.google.com/maps/dir/?api=1&origin=${encodeURI(
            dist.address_1 +
              ' ' +
              (dist.address_2 || '') +
              ' ' +
              (dist.city || '') +
              ' ' +
              (dist.state || '') +
              ' ' +
              (dist.zip || '')
          )}&travelmode=driving`"
        >Directions</a>
        <p v-if="dist.distance" class="d-inline">{{ distancesInMiles[i] }} mi</p>
        <h5 class="mt-3 mb-1">STI Territory Manager</h5>
        <div class="d-flex flex-column">
          <p class="mb-1">{{ dist.rep.name }}</p>
          <a :href="`mailto:${dist.rep.email}`">{{ dist.rep.email }}</a>
          <a :href="`tel:${dist.rep.phone}`">{{ dist.rep.phone }}</a>
        </div>
      </div>
    </div>
    <div id="distributor-map" />
  </div>
</template>

<script>
export default {
  props: { data: { type: Array, default: () => [] } },
  data() {
    return {
      mapObject: null,
      markers: null,
      infoWindows: null
    };
  },
  computed: {
    distancesInMiles() {
      return this.data.map(({ distance }) => {
        const km = distance.value;
        return (km / 1.609344).toFixed(1);
      });
    }
  },
  mounted() {
    this.initMap();
    this.addMarkers();
    this.addInfoWindows();

    // delay until all markers are drawn on map
    setTimeout(this.setZoomLevel, 100);
  },
  beforeDestroy() {
    // cleanup listeners
    // this.markers.forEach(marker =>
    //   window.google.maps.clearInstanceListeners(marker)
    // )
  },
  methods: {
    initMap() {
      const mapEl = document.getElementById("distributor-map");
      const { lat, lng } = this.data[0];
      this.mapObject = new window.google.maps.Map(mapEl, {
        zoom: 15,
        center: { lat, lng }
      });
    },
    addMarkers() {
      this.markers = this.data.map(dist => {
        const { lat, lng } = dist;
        return new window.google.maps.Marker({
          position: { lat, lng },
          animation: window.google.maps.Animation.DROP,
          map: this.mapObject,
          title: dist.name,
          // icon: 'http://maps.google.com/mapfiles/kml/paddle/blu-circle.png'
          icon: "https://assets.stifirestop.com/images/sti-site/checkmark.png"
        });
      });
    },
    addInfoWindows() {
      this.infoWindows = this.data.map((dist, i) => {
        // Build content in infowindow
        const contentString = `
        <h4>${dist.name}</h4>
        ${dist.description ? "<h6>" + dist.description + "</h6>" : ""}
        <p class="mb-1">${dist.address_1} ${dist.address_2 || ""}</p>
        <p class="mb-1">${dist.city}, ${dist.state} ${dist.zip}</p>
        <p class="mb-1"><b>Phone: </b>${dist.phone}</p>
        <p class="mb-3"><a href="dist.url" target="_blank">${dist.url}</a></p>
        <a href="https://www.google.com/maps/dir/?api=1&origin=${encodeURI(
          dist.address_1 +
            " " +
            (dist.address_2 || "") +
            " " +
            dist.city +
            " " +
            dist.state +
            " " +
            dist.zip
        )}&travelmode=driving">Directions</a>
        `;
        // create infowindow object
        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString
        });
        const marker = this.markers[i];

        // attach to associated marker
        marker.addListener("click", () => {
          this.infoWindows.forEach(m => m.close());
          infoWindow.open(this.mapObject, marker);
        });

        return infoWindow;
      });
    },
    openInfowindow(i) {
      this.infoWindows.forEach(m => m.close());
      this.infoWindows[i].open(this.mapObject, this.markers[i]);
    },
    setZoomLevel() {
      const bounds = new window.google.maps.LatLngBounds();
      this.markers.map(marker => {
        if (marker.getVisible()) bounds.extend(marker.getPosition());
      });
      this.mapObject.fitBounds(bounds);
    }
  }
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 1.2em;
}

h4 {
  cursor: pointer;
  color: #0063a1;
}
h5 {
  font-size: 16px;
}

#distributor-list {
  width: 400px;
  max-height: 500px;
  overflow-y: scroll;
}
#distributor-map {
  flex: 1 1 auto;
  height: 500px;
}

//custom scrollbar

#distributor-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

#distributor-list::-webkit-scrollbar {
  width: 9px;
  background-color: #f5f5f5;
}

#distributor-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@media only screen and (max-width: 768px) {
  .map-row {
    flex-direction: column-reverse;
  }
  #distributor-list {
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
  }
  #distributor-map {
    height: 400px;
    margin: 0.5em 0.5em;
  }
}
</style>
<style lang="scss">
#distributor-map p {
  font-size: 14px;
}
</style>
