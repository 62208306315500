<template>
  <div class="contact-item">
    <p :class="unregistered ? 'font-weight-bold' : 'small'">
      {{user.name ? name : "User Unregistered"}}
      <b-button
        @click="$emit('removeUser', user.email)"
        variant="danger"
        size="sm"
        class="remove-user"
        v-if="myId.email !== user.email && isAdmin"
        v-b-popover.hover.top="`Remove ${user.name} from ${projectName}`"
      >X</b-button>
    </p>
    <p>{{ user.title }}</p>
    <p>
      <a :href="'mailto:'+user.email">{{user.email}}</a>
    </p>
    <a class="phone" :href="'tel:' + user.phone">
      <i v-if="user.phone" class="fa fa-phone-square"/>
      {{user.phone}}
    </a>
  </div>
</template>

<script>
export default {
  props: ["user", "isAdmin", "projectName", "myId"],
  computed: {
    unregistered() {
      return this.user.name ? true : false;
    },
    name() {
      return this.user.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-item {
  text-align: center;
  margin: 10px;
  min-width: 177px;
}
p {
  margin: 0;
  line-height: 1.7;
}
.remove-user {
  font-size: 10px;
  padding: 0px;
  width: 16px;
  margin-bottom: 2px;
}
a.phone {
  color: black;
  text-decoration: none;
}
</style>

