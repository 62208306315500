var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex map-row"},[_c('div',{attrs:{"id":"distributor-list"}},[_c('h3',{staticClass:"text-center"},[_vm._v("Distributors")]),_vm._l((_vm.data),function(dist,i){return _c('div',{key:dist.id,staticClass:"distributor-item border-bottom px-2 py-4"},[_c('h4',{on:{"click":function($event){return _vm.openInfowindow(i)}}},[_vm._v(_vm._s(dist.name))]),(dist.description)?_c('p',[_vm._v(_vm._s(dist.description))]):_vm._e(),_c('p',[_vm._v(" "+_vm._s(dist.address_1)+" "+_vm._s(dist.address_2)+" "),_c('br'),_vm._v(" "+_vm._s(dist.city || '')+", "+_vm._s(dist.state || '')+" "+_vm._s(dist.zip || '')+" ")]),_c('p',[_c('b',[_vm._v("Phone:")]),_c('a',{attrs:{"href":`tel:${dist.phone}`}},[_vm._v(_vm._s(dist.phone))])]),_c('a',{attrs:{"target":"_blank","href":`https://www.google.com/maps/dir/?api=1&origin=${encodeURI(
          dist.address_1 +
            ' ' +
            (dist.address_2 || '') +
            ' ' +
            (dist.city || '') +
            ' ' +
            (dist.state || '') +
            ' ' +
            (dist.zip || '')
        )}&travelmode=driving`}},[_vm._v("Directions")]),(dist.distance)?_c('p',{staticClass:"d-inline"},[_vm._v(_vm._s(_vm.distancesInMiles[i])+" mi")]):_vm._e(),_c('h5',{staticClass:"mt-3 mb-1"},[_vm._v("STI Territory Manager")]),_c('div',{staticClass:"d-flex flex-column"},[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(dist.rep.name))]),_c('a',{attrs:{"href":`mailto:${dist.rep.email}`}},[_vm._v(_vm._s(dist.rep.email))]),_c('a',{attrs:{"href":`tel:${dist.rep.phone}`}},[_vm._v(_vm._s(dist.rep.phone))])])])})],2),_c('div',{attrs:{"id":"distributor-map"}})])
}
var staticRenderFns = []

export { render, staticRenderFns }